import { PaletteOptions, PaletteColor } from '@material-ui/core/styles/createPalette';

declare module '@material-ui/core/styles/createPalette' {
	interface Palette {
		indigo: PaletteColor;
		daisy: PaletteColor;
		pru: PaletteColor;
		picnic: PaletteColor;
		tazz: PaletteColor;
		saffron: PaletteColor;
		mamba: PaletteColor;
		success: PaletteColor;
		rentleGrey: RentleGreyColor;
		peach: PaletteColor;
		tundra: PaletteColor;
	}

	interface PaletteOptions {
		indigo: SimplePaletteColorOptions;
		daisy: SimplePaletteColorOptions;
		pru: SimplePaletteColorOptions;
		picnic: SimplePaletteColorOptions;
		tazz: SimplePaletteColorOptions;
		saffron: SimplePaletteColorOptions;
		mamba: SimplePaletteColorOptions;
		// success: SimplePaletteColorOptions, // added to material-ui?
		rentleGrey: RentleGreyColor;
		peach: SimplePaletteColorOptions;
		tundra: SimplePaletteColorOptions;
	}

	interface TypeBackground {
		lightGrey: string;
		lighterGrey: string;
	}
}

interface RentleGreyColor extends PaletteColor {
	divider: string;
	inputBorder: string;
	chipBorder: string;
}

export const indigo: PaletteColor = {
	main: '#260178',
	light: '#EBE8F3',
	dark: '#1E015F',
	contrastText: '#fff',
};

export const daisy: PaletteColor = {
	main: '#5A3D9A',
	light: '#E3D6FF',
	dark: '#452F75',
	contrastText: '#fff',
};

export const pru: PaletteColor = {
	main: '#0E002C',
	light: '#C3BCD7',
	dark: '#060013',
	contrastText: '#fff',
};

export const picnic: PaletteColor = {
	main: '#3BD691',
	light: '#C6F2DE',
	dark: '#22A369',
	contrastText: '#fff',
};

export const tazz: PaletteColor = {
	main: '#D00051',
	light: '#FFE5EF',
	dark: '#9D003D',
	contrastText: '#fff',
};

export const tazz_online: PaletteColor = {
	main: '#ce0000',
	light: '#FFE5EF',
	dark: '#9D003D',
	contrastText: '#fff',
};

/**
 * Mamba here is wrong, should be #595368
 */
export const mamba: PaletteColor = {
	main: '#484848',
	light: '#484848',
	dark: '#484848',
	contrastText: '#fff',
};

export const tundra: PaletteColor = {
	main: '#484848',
	light: '#484848',
	dark: '#484848',
	contrastText: '#fff',
};

export const rentleGrey: RentleGreyColor = {
	main: '#ACA9B3',
	light: '#F2F2F2',
	dark: '#484848', // Mamba color
	contrastText: '#000',
	divider: '#d8d8d8',
	inputBorder: '949494',
	chipBorder: '#e1e1e1',
};

export const white: PaletteColor = {
	main: '#ffffff',
	light: '#ffffff',
	dark: '#ffffff',
	contrastText: '#000000',
};

export const black: PaletteColor = {
	main: '#000000',
	light: '#000000',
	dark: '#000000',
	contrastText: '#ffffff',
};

export const saffron: PaletteColor = {
	main: '#f5b53e',
	light: '#FFF4E5',
	dark: '#e8a11d', // Not in official library
	contrastText: '#fff',
};

export const peach: PaletteColor = {
	main: '#ffc6a9',
	light: '#ffc6a9',
	dark: '#ffc6a9',
	contrastText: '#ffc6a9',
};

export const paletteLight: PaletteOptions = {
	type: 'light',
	indigo,
	daisy,
	pru,
	picnic,
	tazz,
	saffron,
	mamba,
	rentleGrey,
	peach,
	tundra,
	common: {
		black: '#000',
		white: '#fff',
	},
	primary: indigo,
	secondary: mamba,
	error: tazz,
	success: picnic,
	info: indigo,
	grey: {
		'50': '#fafafa',
		'100': '#f5f5f5',
		'200': '#efefef',
		'300': '#e0e0e0',
		'400': '#bdbdbd',
		'500': '#9e9e9e',
		'600': '#757575',
		'700': '#616161',
		'800': '#424242',
		'900': '#212121',
		A100: '#d5d5d5',
		A200: '#aaaaaa',
		A400: '#303030',
		A700: '#616161',
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	text: {
		primary: '#000',
		secondary: mamba.main,
		disabled: rentleGrey.main,
		hint: rentleGrey.main,
	},
	divider: rentleGrey.light,
	background: {
		paper: '#fff',
		default: '#fafafa',
		lightGrey: '#e2e2e2',
		lighterGrey: '#f2f0f7',
	},
	action: {
		active: 'rgba(0, 0, 0, 0.54)',
		hover: 'rgba(0, 0, 0, 0.08)',
		hoverOpacity: 0.08,
		selected: 'rgba(0, 0, 0, 0.14)',
		disabled: rentleGrey.main,
		disabledBackground: '#cfcfcf',
	},
};

export const paletteDark: PaletteOptions = {
	type: 'dark',
	indigo,
	daisy,
	pru,
	picnic,
	tazz,
	saffron,
	mamba,
	rentleGrey,
	peach,
	tundra,
	common: {
		black: pru.main,
		white: '#fff',
	},
	primary: white,
	grey: {
		'50': '#fafafa',
		'100': '#f5f5f5',
		'200': '#eeeeee',
		'300': '#e0e0e0',
		'400': '#bdbdbd',
		'500': '#9e9e9e',
		'600': '#757575',
		'700': '#616161',
		'800': '#424242',
		'900': '#212121',
		A100: '#d5d5d5',
		A200: '#aaaaaa',
		A400: '#303030',
		A700: '#616161',
	},
	text: {
		primary: 'rgba(255,255,255,0.98)',
		secondary: '#cfcfcf',
		disabled: 'rgba(255, 255, 255, 0.38)',
		hint: 'rgba(255, 255, 255, 0.38)',
	},
	background: {
		paper: '#282828',
		default: '#181818',
	},
	secondary: mamba,
	error: tazz,
	success: picnic,
	info: indigo,
	contrastThreshold: 3,
	tonalOffset: 0.2,
};

export const neutralLight: PaletteOptions = {
	...paletteLight,
	primary: black,
	tazz: tazz_online,
};

export const STATE_COLORS = {
	danger: '#ce0000',
	success: '#008609',
	warning: '#febc39',
	info: '#000000',
};
