import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeOptions, fade } from '@material-ui/core/styles';

import * as Colors from './colors';
import * as Fonts from './fonts';
import { getOverrides } from './overrides';

export const Base: Partial<ThemeOptions> = {
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl'],
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	direction: 'ltr',
	mixins: {
		toolbar: {
			minHeight: 56,
			'@media (min-width:0px) and (orientation: landscape)': {
				minHeight: 48,
			},
			'@media (min-width:600px)': {
				minHeight: 64,
			},
		},
	},
	typography: {
		fontFamily: Fonts.inter,
		htmlFontSize: 10,
	},
	shape: {
		borderRadius: 4,
	},
	spacing: 8,
};

/**
 * Base, white-labelable light theme (online, etc.)
 */
export const Light = createMuiTheme({
	...Base,
	typography: {
		...Base.typography,
		subtitle2: {
			fontWeight: 400,
		},
	},
	palette: Colors.neutralLight,
});

Light.overrides = getOverrides(Light, {
	extras: {
		MuiFab: {
			root: {
				borderRadius: 0,
			},
			extended: {
				borderRadius: 0,
			},
		},
		MuiButton: {
			root: {
				borderRadius: 0,
			},
		},
	},
});

/**
 * Base, white-labelable dark theme (online, etc.)
 */
export const Dark = createMuiTheme({
	...Base,
	palette: Colors.paletteDark,
});

Dark.overrides = getOverrides(Dark);

/**
 * Rentle-branded light theme (consumer app etc.)
 */
export const RentleLight = createMuiTheme({
	...Base,
	palette: Colors.neutralLight,
});

RentleLight.overrides = getOverrides(RentleLight, {
	titleFontFamily: Fonts.circular,
	extras: {
		MuiButton: {
			root: {
				borderRadius: '17.5px',
				'&$disabled': {
					backgroundColor: fade(RentleLight.palette.primary.main, 0.8),
				},
			},
			label: {
				textTransform: 'none',
				padding: RentleLight.spacing(0, 1),
			},
			containedSizeLarge: {
				borderRadius: '3px',
				minHeight: '51px',
				fontSize: '18px',
			},
		},
		MuiLink: {
			button: {
				textTransform: 'uppercase',
				textDecoration: 'underline',
				fontSize: '12px',
			},
		},
	},
});

/**
 * Rentle-branded dark theme (consumer app etc.)
 */

export const RentleDark = createMuiTheme({
	...Base,
	palette: Colors.paletteDark,
});

RentleDark.overrides = getOverrides(RentleDark, {
	titleFontFamily: Fonts.circular,
	extras: {
		MuiButton: {
			root: {
				borderRadius: '17.5px',
				'&$disabled': {
					backgroundColor: fade(RentleDark.palette.primary.main, 0.8),
				},
			},
			label: {
				textTransform: 'none',
				padding: RentleDark.spacing(0, 1),
			},
			containedSizeLarge: {
				borderRadius: '3px',
				minHeight: '51px',
				fontSize: '18px',
			},
		},
		MuiLink: {
			button: {
				textTransform: 'uppercase',
				textDecoration: 'underline',
				fontSize: '12px',
			},
		},
	},
});

/**
 * Admin light theme
 */

export const AdminLight = createMuiTheme({
	...Base,
	palette: Colors.paletteLight,
});

AdminLight.overrides = getOverrides(AdminLight, {
	titleFontFamily: Fonts.circular,
	extras: {
		MuiIconButton: {
			root: {
				color: Colors.pru.main,
			},
		},
		MuiButton: {
			root: {
				borderRadius: 50,
				minWidth: 112,
				minHeight: 40,
				fontWeight: 700,
			},
			contained: {
				'&$disabled': {
					color: '#fff',
				},
			},
		},
	},
});
