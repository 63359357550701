import { GoogleAnalytics } from 'common/types';

export const NO_CATEGORY = 'no_category';
export const NO_SHOPID = 'no_shopId';
export const NO_ORDERID = 'no_orderId';
export const NO_SHOPNAME = 'no_shopName';
export const NO_VARIANTS = 'no_variants';

export const adminWebsiteMeasurementId = 'G-NQBPY7YWZW';

export const adminWebsiteGaTag: GoogleAnalytics = {
	GA4: [adminWebsiteMeasurementId],
};
