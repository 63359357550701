import './fonts.scss';
import { CSSProperties } from 'react';

declare module '@material-ui/core/styles/createTypography' {
	interface FontStyleOptions {
		paragraphFont: CSSProperties['fontFamily'];
	}

	interface FontStyle {
		paragraphFont: CSSProperties['fontFamily'];
	}
}

export const circular = '"CircularStd", "Helvetica", "Arial", sans-serif';
export const inter = '"Inter", "Helvetica", "Arial", sans-serif';
