import { isEqual } from 'lodash';
/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
interface DiffResult<T> {
	removedKeys: (keyof T)[];
	addedKeys: (keyof T)[];
	updatedKeys: (keyof T)[];
}

export const getObjectKeysDiff = <T extends object>(
	newObj: Partial<T>,
	oldObj: Partial<T>,
): DiffResult<T> => {
	const newObjKeys = Object.keys(newObj);
	const oldObjKeys = Object.keys(oldObj);
	const removedKeys = oldObjKeys.filter((k) => !newObj.hasOwnProperty(k)) as (keyof T)[];
	const addedKeys = newObjKeys.filter((k) => !oldObj.hasOwnProperty(k)) as (keyof T)[];
	const updatedKeys = newObjKeys.filter(
		(k) => oldObj.hasOwnProperty(k) && newObj.hasOwnProperty(k) && !isEqual(newObj[k], oldObj[k]),
	) as (keyof T)[];
	return {
		removedKeys,
		addedKeys,
		updatedKeys,
	};
};

/** Utility function to create a K:V from a list of strings */
/* From https://basarat.gitbook.io/typescript/type-system/literal-types#string-based-enums */
export const stringEnum = <T extends string>(o: T[]): { [K in T]: K } => {
	return o.reduce((res, key) => {
		res[key] = key;
		return res;
	}, Object.create(null));
};

export const getTypedKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
