import { CookiebotService } from 'common/types/cookies';
import { CookieShortCode, CookieCategories } from 'common/utils/cookies';

const CookieQueue: Record<CookieShortCode, any[]> = {
	[CookieCategories.NECESSARY]: [],
	[CookieCategories.STATISTICS]: [],
	[CookieCategories.MARKETING]: [],
	[CookieCategories.PREFERENCE]: [],
};

const hasConsent = (code: CookieShortCode, consent: CookiebotService['consent']) => {
	return (
		(code === CookieCategories.MARKETING && consent.marketing) ||
		(code === CookieCategories.PREFERENCE && consent.preferences) ||
		(code === CookieCategories.STATISTICS && consent.statistics) ||
		code === CookieCategories.NECESSARY
	);
};

export const executeWhenConsent = (
	consentCode: CookieShortCode,
	action: (...args: any[]) => any,
) => {
	const Cookiebot = (window as any).Cookiebot as CookiebotService | undefined;
	// Cookiebot always exists if we are using it to block cookies.
	// If this is called from a file that doesn't use Cookiebot, we want to execute it directly
	if (!Cookiebot) {
		action();
	} else if (!!Cookiebot.consent && hasConsent(consentCode, Cookiebot.consent)) {
		action();
	} else {
		CookieQueue[consentCode].push(action);
	}
};

export const executeCookieQueue = (consent: CookiebotService['consent']) => {
	if (consent.statistics) {
		CookieQueue[CookieCategories.STATISTICS].forEach((action) => action());
		CookieQueue[CookieCategories.STATISTICS] = [];
	}
	if (consent.preferences) {
		CookieQueue[CookieCategories.PREFERENCE].forEach((action) => action());
		CookieQueue[CookieCategories.PREFERENCE] = [];
	}
	if (consent.marketing) {
		CookieQueue[CookieCategories.MARKETING].forEach((action) => action());
		CookieQueue[CookieCategories.MARKETING] = [];
	}
};
