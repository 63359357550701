import { EnumValues } from 'common/types';
import { CookiebotService } from 'common/types/cookies';
import { stringEnum } from 'common/utils/objects';
import { getFromStorage, saveToStorage, StorageKeys, Storages } from './frontUtils';

// Pre-declared Cookiebot events that can be listened to: https://www.cookiebot.com/en/developer/
export const CookieBotEvents = stringEnum([
	'CookiebotOnConsentReady',
	'CookiebotOnLoad',
	'CookiebotOnAccept',
	'CookiebotOnDecline',
	'CookiebotOnDialogInit',
	'CookiebotOnDialogDisplay',
	'CookiebotOnTagsExecuted',
]);

export const CookieCategories = {
	NECESSARY: 'nec',
	PREFERENCE: 'pre',
	STATISTICS: 'sta',
	MARKETING: 'mar',
} as const;

export type CookieShortCode = EnumValues<typeof CookieCategories>;

export type RentleCookieConsent = CookiebotService['consent'] & {
	type: 'banner' | 'parameter';
	timestamp: string;
};

export const getCookieConsentForShop = (shopName: string): null | RentleCookieConsent => {
	const consents = getCookieConsents();
	return consents[shopName];
};

const getCookieConsents = () => {
	const oldConsentObjString = getFromStorage(Storages.LOCAL, StorageKeys.COOKIE_CONSENTS);
	return oldConsentObjString ? JSON.parse(oldConsentObjString) ?? {} : {};
};

export const updateCookieConsents = (
	shopName: string,
	consent: CookiebotService['consent'],
	type: 'banner' | 'parameter',
	timestamp: string,
) => {
	const oldConsentObj = getCookieConsents();
	const newConsentObj = {
		...oldConsentObj,
		[shopName]: {
			...consent,
			type,
			timestamp,
		},
	};
	saveToStorage(Storages.LOCAL, StorageKeys.COOKIE_CONSENTS, JSON.stringify(newConsentObj));
};
