import { getNewOnlineBaseUrl as getBaseUrl } from 'common/utils/common';
import { getClientEnv } from 'common/utils/frontUtils';
import { matchPath } from 'react-router-dom';
import {
	SUMMARY,
	RESPONSIBLE,
	CANCELLATION,
	CONFIRMATION,
	CONFIRMED,
	SHOPPER_DETAILS,
	ONE_LOCATION_CATEGORY_PRODUCTS,
	ONE_LOCATION_CATEGORY_SINGLE_PRODUCT,
	ONE_LOCATION_OTHER_PRODUCTS,
	ONE_LOCATION_OTHER_SINGLE_PRODUCT,
	ONE_LOCATION_PRODUCTS,
	ONE_LOCATION_SINGLE_PRODUCT,
} from '../routing/routes';

export const notARedirectPath = [
	SUMMARY,
	RESPONSIBLE,
	CANCELLATION,
	CONFIRMATION,
	CONFIRMED,
	SHOPPER_DETAILS,
]
	.map((r) => r.replace('/:shopName/', ''))
	.some((p) => window.location.pathname.includes(p));

const shopPath = (shopName: string) => `/${shopName}`;
const productPath = (productId: string) => `product/${productId}`;
const categoryPath = (categoryId: string) => `shop?category=${categoryId}`;
const getNewOnlineTicketsPath = (shopName: string) => `${shopPath(shopName)}/tickets`; //Old online has no location specific tickets page

const queryParamsStr = (lang: string) => {
	const queryParams = window.location.search.substring(1).replace(/lang=[a-zA-Z]{2,}/, ''); //language assigned from state
	return `lang=${lang}${!!queryParams ? `&${queryParams}` : ''}`;
};

const isSingleLocationUrl = (pathname: string) => {
	return matchPath(pathname, {
		path: [
			ONE_LOCATION_CATEGORY_PRODUCTS,
			ONE_LOCATION_CATEGORY_SINGLE_PRODUCT,
			ONE_LOCATION_OTHER_PRODUCTS,
			ONE_LOCATION_OTHER_SINGLE_PRODUCT,
			ONE_LOCATION_PRODUCTS,
			ONE_LOCATION_SINGLE_PRODUCT,
		],
		exact: true,
		strict: false,
	});
};

export const getRedirectUrl = (shopName: string) => {
	const { pathname } = window.location;

	let [, _shopName, locationName] = pathname.match(/\/(.*?)\/(.*?)\//) || pathname.split('/');
	if (isSingleLocationUrl(pathname)) {
		locationName = '';
	}

	const shopLocationPath =
		!!_shopName && !!locationName ? `/${_shopName}/l/${locationName}/` : `/${_shopName}/`;
	const [, categoryId, productId] =
		pathname.match(/\/categories\/(.*?)\/(?:products\/(.*?)\/)?/) || [];

	const isTicketPath = pathname.includes('/tickets');

	const path = !!productId
		? `${shopLocationPath}${productPath(productId)}?`
		: !!categoryId
		? `${shopLocationPath}${categoryPath(categoryId)}&`
		: isTicketPath
		? `${getNewOnlineTicketsPath(shopName)}?`
		: !!shopLocationPath
		? `${shopLocationPath}?`
		: `${shopLocationPath}?`;

	const additionalQueryParams = window.location.search;
	const queryParamsWithoutQuestionMark = additionalQueryParams.replace('?', '');
	const fullPath = !!queryParamsWithoutQuestionMark
		? path + queryParamsWithoutQuestionMark
		: path.slice(0, -1);
	return `${getBaseUrl(getClientEnv())}${fullPath}`;
};

export const redirectToNewOnline = (shopName: string) => {
	const redirectUrl = getRedirectUrl(shopName);
	console.log(redirectUrl);
	window.location.replace(redirectUrl);
};
