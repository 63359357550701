import { Theme } from '@material-ui/core/styles';
import { merge } from 'lodash';

import * as Fonts from './fonts';

interface OverrideOptions {
	fontFamily?: string;
	titleFontFamily?: string;
	extras?: Partial<Theme['overrides']>;
}

const defaultOptions: OverrideOptions = {
	fontFamily: Fonts.inter,
	titleFontFamily: Fonts.inter,
	extras: {},
};

export const getOverrides = (
	theme: Theme,
	options: OverrideOptions = defaultOptions,
): Theme['overrides'] => {
	const base = {
		MuiCssBaseline: {
			'@global': {
				'*': {
					fontFamily: options.fontFamily ?? defaultOptions.fontFamily,
				},
				html: {
					boxSizing: 'border-box',
					height: '100%',
					width: '100%',
					fontSize: '62.5%',
					'&.large-font': {
						fontSize: '68.75%',
					},
				},
				'*, *:before, *:after': {
					boxSizing: 'inherit',
				},
				body: {
					margin: 0,
					padding: 0,
					height: '100%',
					width: '100%',
					touchAction: 'manipulation',
				},
				'#root': {
					width: '100%',
					height: '100%',
				},
				a: {
					color: 'inherit',
					textDecoration: 'none',
				},
				'.MuiTypography-root a': {
					fontWeight: 500,
				},
			},
		},
		MuiFab: {
			root: {
				borderRadius: 50,
			},
			extended: {
				borderRadius: 50,
			},
		},
		// https://github.com/mui-org/material-ui/issues/17701
		MuiMenuItem: {
			root: {
				paddingTop: 4,
				paddingBottom: 4,
				[theme.breakpoints.up('sm')]: {
					minHeight: 48,
				},
			},
		},
		MuiTypography: {
			h1: {
				fontWeight: 500,
				fontFamily: options.titleFontFamily ?? defaultOptions.titleFontFamily,
			},
			h2: {
				fontWeight: 500,
				fontFamily: options.titleFontFamily ?? defaultOptions.titleFontFamily,
			},
			h3: {
				fontWeight: 500,
			},
			h4: {
				fontWeight: 500,
			},
			h5: {
				fontWeight: 500,
			},
			h6: {
				fontWeight: 500,
			},
			button: {
				fontWeight: 700,
			},
			caption: {
				fontWeight: 500,
			},
			body2: {
				fontWeight: 500,
			},
		},
		MuiFormLabel: {
			root: {
				fontWeight: 500,
			},
		},
		MuiTab: {
			wrapper: {
				fontWeight: 500,
			},
		},
		MuiLink: {
			root: {
				fontWeight: 500,
			},
		},
		MuiFormHelperText: {
			root: {
				fontWeight: 500,
			},
		},
		MuiListItemText: {
			primary: {
				fontWeight: 500,
			},
		},
		MuiSelect: {
			root: {
				fontWeight: 500,
			},
		},
		MuiButton: {
			root: {
				borderRadius: 50,
				minWidth: 112,
				minHeight: 40,
				fontWeight: 700,
			},
		},
		MuiStepLabel: {
			root: {
				fontWeight: 500,
			},
		},
	};

	return merge(base, options.extras);
};
