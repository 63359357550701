import { analytics as FirebaseAnalytics } from 'common/services/firebase';
import { GoogleAnalytics } from 'common/types';
import { Events, EventName, EcomEventName, UserProperties, EcomEvent } from './types';
import { isEmpty } from 'lodash';
import { getFormattedUrlPath } from './utils';
import errorHandler from '../errorHandler';
import { isDevEnv } from 'common/utils/common';
import * as Gtag from './gtag';
import * as Fbq from './fbq';
import { mapToUaEvent } from './ecomEventMapping';
import { isLocalhost } from 'common/utils/browserUtils';
import { adminWebsiteMeasurementId } from './constants';

export const logEvent = <T extends Exclude<EventName, EcomEventName>>(name: T, params?: Events[T]): void => {
	const eventName = name as string;
	FirebaseAnalytics.logEvent(eventName, params);
}

export const logEcomEvent = <T extends EcomEventName>(event: EcomEvent<T>, shopGa?: GoogleAnalytics): void => {
	const { name, params } = event;
	const eventName = name as string;
	FirebaseAnalytics.logEvent(eventName, params);
	// If we do debugging locally using prod, don't send analytics
	if (isLocalhost() && !isDevEnv()) return;
	if (shopGa && !isEmpty(shopGa)) {
		const { UA, GA4 } = shopGa;
		if (!!GA4?.length) {
			Gtag.sendEvent(name, { ...params, send_to: GA4 });
		}
		if (!!UA?.length) {
			const uaEvent = mapToUaEvent(event);
			if (uaEvent) {
				Gtag.sendEvent(uaEvent.name, { ...uaEvent.params, send_to: UA });
			}
		}
	}
}

export const setUserProperties = (userProperties: UserProperties) => {
	const { user_id, ...customUserProperties } = userProperties;
	if (user_id) {
		FirebaseAnalytics.setUserId(user_id);
	}
	if (!isEmpty(customUserProperties)) {
		FirebaseAnalytics.setUserProperties(customUserProperties);
	}
};

export const pageView = async (url: string, shopGa?: GoogleAnalytics) => {
	const formattedUrl = getFormattedUrlPath(url);
	const pageViewParams = { page_path: formattedUrl };
	try {
		FirebaseAnalytics.logEvent('page_view', pageViewParams);
	} catch (e) {
		errorHandler.report(e);
	}
	// If we do debugging locally using prod, don't send analytics
	if (isLocalhost() && !isDevEnv()) return;
	if (shopGa && !isEmpty(shopGa)) {
		try {
			const { UA, GA4 } = shopGa;
			if (!!GA4?.length) {
				Gtag.sendPageView({ ...pageViewParams, send_to: GA4 });
			}
			if (!!UA?.length) {
				Gtag.sendPageView({ ...pageViewParams, send_to: UA });
			}
		} catch (e) {
			errorHandler.report(e);
		}
	}
	if (!isDevEnv()) {
		try {
			const GTAG_ANALYTICS_ID_PRODUCTION = 'UA-116407175-2';
			Gtag.sendPageView({
				...pageViewParams,
				send_to: GTAG_ANALYTICS_ID_PRODUCTION,
			});
		} catch (e) {
			errorHandler.report(e);
		}
	}
};

export const liteSignupConversion = async () => {
	try {
		Gtag.sendAdConversionEvent('lite_signup_conversion');
	} catch (e) {
		errorHandler.report(e);
	}
	try {
		logEvent('rentle_lite_signup');
	} catch (e) {
		errorHandler.report(e);
	}
	try {
		Gtag.sendEvent('rentle_lite_signup', { send_to: adminWebsiteMeasurementId });
	} catch (e) {
		errorHandler.report(e);
	}
	try {
		// prettier-ignore
		Fbq.sendStandardEvent('CompleteRegistration', { value: 0.00, currency: 'EUR' });
	} catch (e) {
		errorHandler.report(e);
	}
};